<template>
  <div class="report petition">
    <div class="ziHead"></div>
    <div class="ziNav">
      <div class="navMain">
        <div class="navMainLeft">
          <span
            class="navMainInner"
            :class="{ active: active == item.entTChannelUuid }"
            v-for="(item, index) in navSubList"
            :key="index"
            @click="handleTab(item, index)"
          >
            {{ item.name }}
          </span>
        </div>

        <div class="navMainRight">
          <span>
            <router-link to="/"><i class="iconfont">&#xe689;</i> <span> &nbsp;> </span> </router-link>
          </span>
          <span> 信访平台 </span>
        </div>
      </div>
    </div>
    <div class="rowContact">
      <div class="reportWrap">
        <el-form ref="ruleForm" :model="form" :rules="rules" label-width="200px">
          <div class="reportInner">
            <div class="retportHd">信访人员信息</div>
            <div class="reportGrop" style="width: 60%;">
              <el-form-item label="信访人姓名：" prop="xfUserName">
                <el-input v-model="form.xfUserName" clearable placeholder="请输入信访人姓名"></el-input>
              </el-form-item>
              <el-form-item label="身份证号码：" prop="xfIdCard">
                <el-input v-model="form.xfIdCard" type="number" clearable placeholder="请输入身份证号码："></el-input>
              </el-form-item>
              <el-form-item label="联系电话：" prop="xfTel">
                <el-input v-model="form.xfTel" type="number" clearable placeholder="请输入联系电话"></el-input>
              </el-form-item>
              <el-form-item label="邮箱：" prop="xfEmail">
                <el-input v-model="form.xfEmail" clearable placeholder="请输入邮箱"></el-input>
              </el-form-item>
              <el-form-item label="您的单位或地址：" prop="xfUserWorkUnit">
                <el-input v-model="form.xfUserWorkUnit" clearable placeholder="请输入您的单位或地址"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="reportInner">
            <div class="retportHd">责任单位信息</div>
            <div class="reportGrop" style="width: 60%;">
              <el-form-item label="责任单位名称：" prop="responsibilityCompanyId">
                <el-select v-model="form.responsibilityCompanyId" placeholder="请选择责任单位" style="width: 100%" @change="changeResponsibility">
                  <el-option v-for="item in optionsBjbUnit" :key="item.value" :label="item.dicItemName" :value="item.dicItemValue">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="相关负责人姓名：" prop="responsibilityUserName">
                <el-input v-model="form.responsibilityUserName" clearable placeholder="请输入相关负责人姓名"></el-input>
              </el-form-item>
              <el-form-item label="涉及分公司和项目：" prop="responsibilityProject">
                <el-input v-model="form.responsibilityProject" clearable placeholder="请输入涉及分公司和项目"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="reportInner">
            <div class="retportHd">投诉信息</div>
            <div class="reportGrop" style="width: 60%;">
              <el-form-item label="投诉标题：" prop="xfTitle">
                <el-input v-model="form.xfTitle" clearable placeholder="请输入投诉标题"></el-input>
              </el-form-item>
              <el-form-item label="投诉类别：" prop="xfTypeId">
                <el-select v-model="form.xfTypeId" placeholder="请选择投诉类别" style="width: 100%" @change="changeXfType">
                  <el-option v-for="item in optionsJbTType" :key="item.value" :label="item.dicItemName" :value="item.dicItemValue">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="具体内容：" prop="xfContent">
                <el-input
                  v-model="form.xfContent"
                  type="textarea"
                  :rows="4"
                  clearable
                  placeholder="请输入投诉内容"
                ></el-input>
              </el-form-item>
              <el-form-item label="验证码:" prop="code">
                <el-input
                  v-model="form.code"
                  type="number"
                  clearable
                  placeholder="请输入验证码"
                  style="width: calc(100% - 150px)"
                ></el-input>
                <div class="codeImg">
                  <s-identify
                    style="display:inline-block; width: 100%; height:40px;vertical-align:top;cursor:pointer;"
                    :identifyCode="code"
                    :contentHeight="40"
                    :contentWidth="140"
                    @click.native="handleRefreshCode"
                  />
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" :loading="loading" @click="submitForm('ruleForm')">立即提交</el-button>
                <el-button @click="resetForm('ruleForm')">重置</el-button>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import SIdentify from '../captcha/index.vue'
import { getChildChannel, addLettersVisits, getDict} from '@/api/common'

export default {
  components: {
    SIdentify
    
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,实际输入的数字总是识别成字符串
        // 所以在前面加了一个+实现隐式转换

        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    }
    return {
      optionsBjbUnit: [],
      optionsJbTType: [],
      loading: false,
      code: '',
      form: {
        xfUserName: '',
        xfTel: '',
        xfType: '',
        xfTypeId: '',
        xfUserWorkUnit: '',
        responsibilityUserName: '',
        responsibilityProject: '',
        xfTitle: '',
        xfContent: '',
        code: '',
        responsibilityCompanyId: '',
        responsibilityCompany: '',
        entTApplicationUuid: this.webId,
        xfIdCard: '',
        xfEmail: '',
      },
      rules: {
        xfUserName: [{ required: true, message: '请输入信访人姓名', trigger: 'blur' }],
        xfIdCard: [{ required: true, message: '请输入身份证号码', trigger: 'blur' }],
        xfTel: [
          { required: true, message: '请输入举报人电话', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ],
        responsibilityUserName: [{ required: true, message: '请输入相关负责人姓名', trigger: 'blur' }],
        responsibilityProject: [{ required: true, message: '请输入涉及分公司和项目', trigger: 'blur' }],
        xfContent: [{ required: true, message: '请输入举报内容', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        responsibilityCompanyId: [{ required: true, message: '请选择责任单位', trigger: 'change' }],
        xfTypeId: [{ required: true, message: '请选择投诉类别', trigger: 'change' }]
      },
      listId: '',
      isDeail: false,
      isList: false,
      active: '',
      fullpath: '/groupProfile/companyProfile',
      pathtitle: '',
      mathedTitle: '集团概况',
      navSubList: [],
      activeHd: 1
    }
  },
  computed: {},
  mounted() {
    var _this = this

    this.handleRefreshCode()
    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    // _this.getSubGrop()
    _this.getDict()
  },
  methods: {
    changeResponsibility(e) {
      var _this = this

      _this.optionsBjbUnit.length && _this.optionsBjbUnit.forEach(line => {
        if (e == line.dicItemValue) {
          _this.form.responsibilityCompany = line.dicItemName
        }
      })
    },
    changeXfType(e) {
      var _this = this

      _this.optionsJbTType.length && _this.optionsJbTType.forEach(line => {
        if (e == line.dicItemValue) {
          _this.form.xfType = line.dicItemName
        }
      })
    },
    // 获取字典值
    getDict(){
      let data01 = {
        dicTypeId:10001 //责任单位名称
      }
      getDict(data01).then(res =>{
        this.optionsBjbUnit = res.data
      })

      let data02 = {
        dicTypeId:10002 //信访问题类别
      }
      getDict(data02).then(res =>{
        this.optionsJbTType = res.data
      })
    },
    
    handleRefreshCode() {
      this.code = (Math.random() * 8999 + 1000).toFixed(0).toString()
    },
    submitForm(formName) {
      var _this = this
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true
          if (this.form.code != this.code) {
            this.$message({
              type: 'warning',
              message: '验证码错误!'
            })
            this.loading = false
            return
          }
          addLettersVisits(_this.form).then(res => {
            const { code, msg } = res
            if (code === 1) {
              this.loading = false
              this.$confirm('提交成功, 我们会及时联系您！', '提示', {
                showConfirmButton: false,
                cancelButtonText: '确定',
                type: 'success',
                center: true
              })
                .then(() => {})
                .catch(() => {
                  _this.resetForm('ruleForm')
                })
            } else {
              this.loading = false
              this.$message({
                type: 'warning',
                message: msg + '提交失败，请稍后重试!'
              })
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 获取二级栏目
    getSubGrop() {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        parentUuid: '0018'
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.navSubList = data
          data.length &&
            data.forEach(line => {
              if (line.entTChannelUuid == _this.listId) {
                _this.pathtitle = line.name
                if (line.catalog == 1) {
                  this.isDeail = true
                } else {
                  this.isList = true
                }
              }
            })
        }
      })
    },
    handleTab(item) {
      this.isDeail = false
      this.isList = false
      this.$router.push(`/groupProfile/${item.entTChannelUuid}`)
      this.pathtitle = item.name
      this.active = item.entTChannelUuid
      this.listId = item.entTChannelUuid
      this.$nextTick(() => {
        if (item.catalog == 1) {
          this.isDeail = true
        } else {
          this.isList = true
        }
      })
    },
    mouseenterHd(val) {
      var _this = this

      _this.activeHd = val
    }
  },
  watch: {
    $route(val) {
      this.fullpath = val.fullPath
      // this.pathtitle = val.meta.title
      // this.mathedTitle = val.matched[val.matched.length - 2].meta.title
    }
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff2?t=1644917438066') format('woff2'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff?t=1644917438066') format('woff'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.ttf?t=1644917438066') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 22px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}

.report {
  width: 100%;
  height: auto;
  overflow: hidden;
  min-height: 902px;
}
.report .ziHead {
  width: 100%;
  height: 417px;
  overflow: hidden;
  background: url(../../assets/image/zihead.jpg) center no-repeat;
  background-size: 100% 100%;
}

.report .ziNav {
  height: 42px;
  border-bottom: 1px #cacaca solid;
  margin-top: 15px;
  width: 100% !important;
}
.report .navMain {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.report .navMainLeft {
  width: 900px;
  float: left;
}
.report .navMainLeft a {
  height: 42px;
  line-height: 42px;
  display: inline-block;
  margin: 0px 5px;
  padding: 0px 10px;
  float: left;
}
.report .navMainLeft a:hover {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.report .navMainLeft a.active {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.report .navMainRight {
  width: 300px;
  float: right;
  overflow: hidden;
  font-size: 13px;
  color: #a2a2a2;
  margin-top: 12px;
  line-height: 24px;
}
.report .navMainRight span {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
  vertical-align: top;
}

.report .navMainRight .textTitle {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
}
.report .ziBase {
  width: auto;
  height: auto;
  overflow: hidden;
}
.report .ziContact {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.report .rowContact {
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 1px 4px #333333;
  padding: 30px 50px;
  background-color: #ffffff;
  line-height: 24px;
  box-sizing: border-box;
  // background-color: rgb(206, 30, 30);
  margin-bottom: 20px;
}
.navMainInner {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  height: 42px;
  line-height: 43px;
  box-sizing: border-box;
  font-size: 17px;
}
.navMainInner.active {
  border-bottom: 2px solid #0263b2;
}
.organizationTitle {
  line-height: 60px;
  text-align: center;
  font-size: 24px;
}
.organizationHd {
  text-align: center;
  margin: 20px 0;
}
.organizationHd span {
  display: inline-block;
  width: 248px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  background-color: #ebf1f5;
  cursor: pointer;
}
.organizationHd span.active {
  background-color: #0162b3;
  color: #ffffff;
}
.reportWrap {
  padding: 6px;
  background-color: #f2f2f2;
}
.reportInner {
  background-color: #fff;
  padding-bottom: 2px;
  margin-bottom: 12px;
}
.reportInner:last-child {
  margin-bottom: 0;
}
.retportHd {
  height: 42px;
  line-height: 42px;
  padding: 0 15px;
  border-bottom: 1px solid #f6f6f6;
  color: #333;
  border-radius: 2px 2px 0 0;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}
.codeImg {
  display: inline-block;
  width: 140px;
  height: 40px;
  margin-left: 10px;
}
@media (max-width: 1300px) {
  .report .navMain,
  .companyProfile,
  .report .rowContact {
    width: 1000px;
  }
  .report .navMainLeft,
  .report .navMainRight {
    width: auto;
  }
}
@media (max-width: 992px) {
  .report .navMain,
  .companyProfile,
  .report .rowContact {
    width: 96%;
  }
  .report .rowContact {
    padding: 20px 10px;
  }
  .companyProfile {
    padding: 20px 20px;
  }
  .companyProfile .contactBody video {
    max-width: 100% !important;
  }
  .companyProfile img {
    max-width: 100% !important;
  }
  .report .ziHead {
    height: 362px;
  }
}
@media (max-width: 768px) {
  .report .navMainLeft {
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .report .navMainRight {
    // display: none;
    // float: left;
  }
  .report .ziHead {
    height: 200px;
  }
  .report .ziContact {
    padding: 0;
    margin: 0;
  }
  .companyProfile {
    padding: 20px 10px;
  }
  .contactBody h1 {
    font-size: 22px !important;
  }
  .report .rowContact {
    padding: 0px 0;
  }
  .reportGrop {
    width: 100% !important;
  }
  .reportGrop .el-form-item__label {
    width: 120px !important;
  }
}
</style>
<style>
.reportGrop .el-select .el-input .el-select__caret {
  color: #C0C4CC;
}
.reportGrop .el-input__suffix {
  top: 0;
}
@media (max-width: 768px) {
  .reportGrop {
    width: 100% !important;
  }
  .petition .el-form-item__label {
    width: 124px !important;
    font-size: 12px !important;
  }
  .petition .el-form-item__content {
    margin-left: 124px !important;
    margin-right: 6px !important;
  }
  .reportWrap {
    padding: 0 !important;
  }
}
</style>
